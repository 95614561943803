export const FeatureFlag = {
    ALLOW_AUDIENCE_EXPORT_TO_FILE: 'audience_allow_audience_export_to_file',
    ALLOW_AUDIENCE_SHARING_TO_META: 'audience_allow_audience_sharing_to_meta',
    ALLOW_AUDIENCE_SMS_CREATION: 'audience_allow_sms_audience_creation',
    ALLOW_SCHEDULE_OR_SEND_EMAIL_CAMPAIGN:
        'audience_allow_schedule_or_send_email_campaigns',
    APPLY_ANALYST_ROLE: 'audience_apply_analyst_role',
    APPLY_APP_REVIEW_VIEW: 'audience_apply_app_review_view',
    ENABLE_EMAIL_CAMPAIGNS_AUDIENCE_CREATION_FILTER:
        'audience_enable_email_campaigns_audience_creation_filter',
    ENABLE_FAN_DATA_LISTS_MULTISELECT:
        'audience_enable_fan_data_lists_multiselect',
    LOOK_LIKE_INTERNAL_EMPLOYEE: 'audience_look_like_internal_employee',
    SHOW_ADD_CAMPAIGNS_TO_REPORT_BUTTON:
        'audience_show_add_campaigns_to_report_button',
    SHOW_AD_REPORTING: 'audience_show_ad_reporting',
    SHOW_AD_REPORTING_NEW_COLUMNS: 'audience_show_new_ad_reporting_columns',
    SHOW_APP_CONNECTIONS: 'audience_show_app_connections',
    SHOW_CITY_FILTER: 'audience_show_city_filter',
    SHOW_EMAIL_CAMPIGNS_PAGE: 'audience_show_email_campaigns_page',
    SHOW_FAN_SEGMENTATION_MODEL: 'audience_show_qa_fan_segmentation_model',
    SHOW_GROUPED_REPORT_ADS: 'audience_show_grouped_report_ads_view',
    SHOW_GOOGLE_AD_ACCOUNTS: 'audience_show_google_ad_accounts',
    SHOW_META_AD_ACCOUNTS: 'audience_show_meta_ad_accounts',
    SHOW_MISSING_ARTISTS_FROM_PARTICIPANT_SEARCH:
        'audience_show_missing_artists_from_participant_search',
    SHOW_RADIUS_CITY_FILTER: 'audience_show_radius_city_filter',
    SHOW_SEGMENT_ACTIVITY_BREAKDOWN: 'audience_show_segment_activity_breakdown',
    SHOW_SHOPIFY_STORES_SECTION: 'audience_show_shopify_stores_section',
    SHOW_SONGWHIP_PRESAVES_FILTER: 'audience_show_songwhip_presaves_filter',
    SHOW_TIKTOK_AD_ACCOUNTS: 'audience_show_tiktok_ad_accounts',
    SKIP_META_AUDIENCE_SHARING_CONNECTION:
        'audience_skip_meta_audience_sharing_connection',
} as const;
