import { lazy } from 'react';
import { initApplication } from '@theorchard/suite-frontend';
import { NavIcon } from '@theorchard/suite-icons';
import { typePolicies } from 'src/apollo/cache/typePolicies';
import { FeatureFlag } from 'src/constants/featureFlags';
import { DefaultTitlePlugin } from 'src/hooks/usePageTitle/defaultTitlePlugin';
import HomePage from 'src/pages/home';
import { ROUTES } from 'src/pages/routes';
import { withSuspence } from 'src/utils/withSuspence';
import { locales } from '../locale';
import { PermissionsPlugin, UrlSanitizerPlugin } from './plugins';

const AudienesPage = lazy(async () => await import('src/pages/audiences'));
const AdReportingPage = lazy(
    async () => await import('src/pages/ad-reporting')
);
const EmailCampaignsPage = lazy(
    async () => await import('src/pages/email-campaigns')
);
const ConnectionsPage = lazy(async () => await import('src/pages/connections'));
const ParticipantPage = lazy(async () => await import('src/pages/participant'));
const SearchPage = lazy(async () => await import('src/pages/search'));
const RosterPage = lazy(async () => await import('src/pages/roster'));

export const render = initApplication({
    locales,
    apollo: { typePolicies },
    config: { auth0StoreTokenInClient: true },
    plugins: [PermissionsPlugin, DefaultTitlePlugin, UrlSanitizerPlugin],
    profileType: 'AudienceProfile',
    featureFlagFilter: 'audience',
    colorScheme: {
        V2: () => true,
    },
    mainNav: {
        beta: true,
        collapsible: true,
        sections: [
            {
                id: 'main',
                items: [
                    {
                        path: ROUTES.SEARCH.path,
                        term: 'navigation.search',
                        icon: <NavIcon name="SearchNavIcon" />,
                        hasAccess: context =>
                            !context.identity.features[
                                FeatureFlag.APPLY_APP_REVIEW_VIEW
                            ],
                    },
                    {
                        path: ROUTES.ROSTER.path,
                        term: 'navigation.roster',
                        icon: <NavIcon name="CatalogNavIcon" />,
                        hasAccess: context =>
                            !context.identity.features[
                                FeatureFlag.APPLY_APP_REVIEW_VIEW
                            ],
                    },
                    {
                        path: ROUTES.AUDIENCES.path,
                        term: 'navigation.audiences',
                        icon: <NavIcon name="UsersNavIcon" />,
                        hasAccess: context =>
                            !context.identity.features[
                                FeatureFlag.APPLY_ANALYST_ROLE
                            ],
                    },
                    {
                        path: ROUTES.AD_REPORTING.path,
                        term: 'navigation.adReporting',
                        icon: <NavIcon name="AdReportingNavIcon" />,
                        hasAccess: context =>
                            !context.identity.features[
                                FeatureFlag.APPLY_APP_REVIEW_VIEW
                            ],
                        featureFlags: [FeatureFlag.SHOW_AD_REPORTING],
                    },
                    {
                        path: ROUTES.EMAIL_CAMPAIGNS.path,
                        term: 'navigation.emailCampaigns',
                        icon: <NavIcon name="EmailNavIcon" />,
                        hasAccess: context =>
                            !context.identity.features[
                                FeatureFlag.APPLY_APP_REVIEW_VIEW
                            ],
                        featureFlags: [FeatureFlag.SHOW_EMAIL_CAMPIGNS_PAGE],
                    },
                ],
            },
            {
                id: 'secondary',
                type: 'secondary',
                items: [
                    {
                        path: ROUTES.CONNECTIONS.path,
                        term: 'navigation.appConnections',
                        icon: <NavIcon name="AppConnectionsNavIcon" />,
                        hasAccess: context =>
                            !context.identity.features[
                                FeatureFlag.APPLY_ANALYST_ROLE
                            ],
                        featureFlags: [FeatureFlag.SHOW_APP_CONNECTIONS],
                    },
                ],
            },
        ],
    },
    routes: [
        { path: '/', page: HomePage },
        {
            path: ROUTES.SEARCH.path,
            page: withSuspence(SearchPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_APP_REVIEW_VIEW],
        },
        {
            path: ROUTES.ROSTER.path,
            page: withSuspence(RosterPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_APP_REVIEW_VIEW],
        },
        {
            path: ROUTES.ARTIST.path,
            page: withSuspence(ParticipantPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_APP_REVIEW_VIEW],
        },
        {
            exact: false,
            path: ROUTES.AUDIENCES.path,
            page: withSuspence(AudienesPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_ANALYST_ROLE],
        },
        {
            exact: false,
            path: ROUTES.AD_REPORTING.path,
            page: withSuspence(AdReportingPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_APP_REVIEW_VIEW],
            featureFlags: [FeatureFlag.SHOW_AD_REPORTING],
        },
        {
            exact: false,
            path: ROUTES.EMAIL_CAMPAIGNS.path,
            page: withSuspence(EmailCampaignsPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_APP_REVIEW_VIEW],
            featureFlags: [FeatureFlag.SHOW_EMAIL_CAMPIGNS_PAGE],
        },
        {
            path: ROUTES.CONNECTIONS.path,
            page: withSuspence(ConnectionsPage),
            hasAccess: context =>
                !context.identity.features[FeatureFlag.APPLY_ANALYST_ROLE],
            featureFlags: [FeatureFlag.SHOW_APP_CONNECTIONS],
        },
    ],
});
